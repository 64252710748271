import React, { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { showToast, toastType } from '../../hooks/useToast';
import { Button, Image, Input } from '@nextui-org/react';
import { emailRegex, passwordRegex } from '../../constants/regex';

const ForgotPasswordPage = () => {
    const [otp, setOtp] = useState('')
    const [otpField, setOtpField] = useState(false)
    const [otpError, setOtpError] = useState('')
    const [token, setToken] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [searchParams] = useSearchParams()

    const [formState, setFormState] = useState({
        email: '',
        emailError: '',
        isLoading: false,
        showPassword: false,
        errors: { email: false, otp: false, password: false, confirmPassword: false }
    });

    const { makeRequest } = useRequest()

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!emailRegex.test(formState.email)) {
            return setFormState(prev => ({ ...prev, errors: { ...prev.errors, email: true } }));
        }

        setFormState(prevState => ({ ...prevState, isLoading: true }))
        makeRequest({
            url: '/api/auth/v1/forgot-password',
            method: 'POST',
            body: { email: formState.email },
            onSuccess: ({ token }) => {
                setFormState(prevState => ({ ...prevState, isLoading: false }))
                setToken(token)
                setOtpField(true)
            },
            onFailure: (error) => {
                setFormState(prevState => ({ ...prevState, isLoading: false }))
                showToast(error.message, toastType.ERROR)
            }
        })
    };

    const verifyOtp = () => {
        setFormState(prevState => ({ ...prevState, isLoading: true }))
        makeRequest({
            url: '/api/auth/v1/verify-otp',
            method: 'POST',
            body: { otp, token },
            onSuccess: () => {
                setFormState(prevState => ({ ...prevState, isLoading: false }))
                setOtpField(false)
                setPasswordError('')
            },
            onFailure: (error) => {
                setFormState(prevState => ({ ...prevState, isLoading: false }))
                setOtpError(showToast(error.message, toastType.ERROR))
            }
        })
    }

    const handlePasswordChange = () => {

        if (!passwordRegex.test(password)) {
            return setPasswordError('Password must include at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character');
        }

        if (password !== confirmPassword) {
            return setPasswordError('Passwords do not match');
        }

        setFormState(prev => ({ ...prev, isLoading: true }))
        makeRequest({
            url: '/api/auth/v1/change-password',
            method: 'POST',
            body: { password, token },
            onSuccess: () => {
                showToast("Password Changed Successfully", toastType.SUCCESS)
                setTimeout(() => {
                    window.location.assign('/login')
                }, 1500)
            },
            onFailure: (error) => {
                setFormState(prevState => ({ ...prevState, isLoading: false }))
                showToast(error.message, toastType.ERROR)
            }
        })
    }

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className='tw-flex tw-flex-col tw-min-h-screen tw-items-center tw-px-4 sm:tw-px-6 lg:tw-px-8'>

            <div className="tw-mt-2 tw-flex tw-items-center tw-w-full tw-justify-center">
                <button name="back" onClick={handleBackClick} className="tw-text-2xl tw-absolute tw-left-4">
                    <ion-icon name={"arrow-back-outline"} />
                </button>
                <Image
                    width="100"
                    loading="lazy"
                    alt=""
                    className="tw-items-center"
                    src="/assets/images/party-connect-hub.svg"
                />
            </div>

            <Image
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                alt=""
                className="tw-items-center tw-my-4"
                width="150"
            />

            <h1 className="tw-text-white tw-text-center tw-text-3xl tw-font-bold tw-mb-2"> PartyConnectHub </h1>

            <p className="tw-text-gray-300 tw-text-center tw-mb-6">
                {!otpField && !token && 'Enter your email to reset your password.'}
                {otpField && 'Enter the OTP sent to your email.'}
                {!otpField && token && 'Enter your new password.'}
            </p>

            <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-4">
                {!otpField && !token && (
                    <Input
                        type="email"
                        name="email"
                        label="Email"
                        variant="flat"
                        value={searchParams.get('search') ?? ''}
                        isInvalid={!!formState.errors.email}
                        errorMessage={formState.emailError}
                        placeholder="Enter your email"
                        classNames={{ errorMessage: "tw-text-left" }}
                        onChange={(e) => {
                            setFormState(prev => ({
                                ...prev,
                                email: e.target.value,
                                emailError: '',
                                errors: { ...prev.errors, email: false }
                            }))
                        }}
                        endContent={
                            <ion-icon name="mail" style={{ fontSize: '1.3rem', color: '#808080' }} />
                        }
                    />
                )}

                {otpField && (
                    <Input
                        type="number"
                        name="otp"
                        label="OTP"
                        variant="flat"
                        placeholder="Enter OTP"
                        value={otp}
                        isInvalid={!!otpError}
                        errorMessage={otpError}
                        classNames={{ errorMessage: "tw-text-left" }}
                        onChange={(e) => { setOtpError(''); setOtp(e.target.value) }}
                    />
                )}

                {!otpField && token && (
                    <>
                        <Input
                            type="password"
                            name="password"
                            label="New Password"
                            variant="flat"
                            placeholder="New Password"
                            value={password}
                            isInvalid={!!passwordError}
                            errorMessage={passwordError}
                            classNames={{ errorMessage: "tw-text-left" }}
                            onChange={(e) => { setPasswordError(''); setPassword(e.target.value) }}
                        />
                        <Input
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            variant="flat"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            isInvalid={!!passwordError}
                            classNames={{ errorMessage: "tw-text-left" }}
                            onChange={(e) => { setPasswordError(''); setConfirmPassword(e.target.value) }}
                        />
                    </>
                )}

                {!otpField && !token && (
                    <Button
                        type="submit"
                        size="lg"
                        variant="solid"
                        className="tw-font-semibold tw-bg-orange-500"
                        isDisabled={formState.isLoading}
                        isLoading={formState.isLoading}
                    >
                        {formState.isLoading ? 'Sending OTP...' : 'Send OTP'}
                    </Button>
                )}
            </form>

            {otpField && (
                <Button
                    size="lg"
                    variant="solid"
                    className="tw-w-full tw-max-w-sm tw-font-semibold tw-bg-orange-500 tw-mt-4"
                    isDisabled={formState.isLoading}
                    isLoading={formState.isLoading}
                    onClick={verifyOtp}
                >
                    {formState.isLoading ? 'Verifying OTP...' : 'Verify OTP'}
                </Button>
            )}

            {!otpField && token && (
                <Button
                    size="lg"
                    variant="solid"
                    className="tw-w-full tw-max-w-sm tw-font-semibold tw-bg-orange-500 tw-mt-4"
                    isDisabled={formState.isLoading}
                    isLoading={formState.isLoading}
                    onClick={handlePasswordChange}
                >
                    {formState.isLoading ? 'Changing Password...' : 'Change Password'}
                </Button>
            )}

            <div className="tw-text-center tw-text-white tw-mt-6">
                Remember your password? <a href="/login" className="tw-font-semibold tw-text-blue-500 hover:tw-underline">Login</a>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
