import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';
import { enivornment } from '../../helpers/environment';

const Profile = () => {
  const [user, setUser] = useState({});

  const { makeRequest } = useRequest();

  useEffect(() => {
    makeRequest({
      url: '/api/user/v1',
      method: 'GET',
      onSuccess: ({ user }) => {
        setUser(user)
      },
      onFailure: (error) => {
        showToast('Failed to load user profile', toastType.ERROR)
      },
    })
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('token')
    window.location.href = '/'
  }

  return (
    <div className="tw-bg-black tw-text-white tw-flex tw-flex-col tw-justify-between tw-items-center sm:tw-px-6 lg:tw-px-8 tw-py-8">
      <div className="tw-w-full tw-max-w-md tw-flex tw-flex-col tw-items-center">
        <div className="tw-bg-[#393737] tw-rounded-2xl tw-p-6 tw-text-center tw-mb-4 tw-w-full">
          <div className="tw-mx-auto tw-w-24 tw-h-24 tw-rounded-full tw-bg-gray-700 tw-flex tw-items-center tw-justify-center tw-mb-4">
            {user.profile ? (
              <img
                src={enivornment === 'LIVE' ? `https://partyconnecthub.com${user.profile}` : `http://localhost:5000${user.profile}`}
                alt=""
                className="tw-w-full tw-h-full tw-object-cover tw-rounded-full tw-border tw-border-orange-500"
              />
            ) : (
              <span className="tw-text-5xl tw-text-gray-600 tw-bg-gray-400 tw-rounded-full tw-w-24 tw-h-24 tw-flex tw-items-center tw-justify-center">
                {user?.name?.charAt(0)}
              </span>
            )}
          </div>

          <div className="tw-flex tw-items-center tw-justify-center tw-mb-6">
            <h3 className="tw-text-2xl tw-font-bold">{user?.name}</h3>
            <Link to="/profile/edit" className="tw-ml-2">
              <ion-icon name="create-outline" class="tw-text-2xl tw-text-white"></ion-icon>
            </Link>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-4">
            {user.role == 'guest' ?
              <a href="/bookings" className="tw-text-center tw-cursor-pointer">
                <h2 className="tw-text-2xl tw-font-bold">{user?.bookings ?? 0}</h2>
                <p className="tw-text-sm tw-text-gray-300">Booking</p>
              </a>
              :
              <a href="/host/bookings/parties" className="tw-text-center tw-cursor-pointer">
                <h2 className="tw-text-2xl tw-font-bold">{user?.parties ?? 0}</h2>
                <p className="tw-text-sm tw-text-gray-300">Parties</p>
              </a>
            }
            <a href="/following" className="tw-text-center tw-cursor-pointer">
              <h2 className="tw-text-2xl tw-font-bold">{user?.following ?? 0}</h2>
              <p className="tw-text-sm tw-text-gray-300">Following</p>
            </a>
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-w-full">
          {[
            { icon: "lock-closed", text: "Privacy", link: "/privacy-policy" },
            { icon: "document-text", text: "Terms & Conditions", link: "/terms-and-conditions" },
            // { icon: "gift", text: "Refer and Earn", link: "#" },
            { icon: "information-circle", text: "About", link: "#" },
            // { icon: "help-circle", text: "Help", link: "#" },
            { icon: "mail", text: "Contact Us", link: "/contact-us" },
            { icon: "refresh", text: "Change Password", link: `/forgot-password?email=${user.email}&role=${user.role}` },
            { icon: "log-out", text: "Logout", link: "#", onClick: handleLogout },
          ].map((item, index) => (
            <Link key={index} to={item.link} className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[#393737] tw-rounded-2xl tw-py-4 tw-px-2 hover:tw-bg-gray-700 tw-transition-colors tw-duration-200" onClick={item.onClick}>
              <ion-icon
                name={item.icon}
                class="tw-text-3xl tw-text-orange-500 tw-mb-2"
              ></ion-icon>
              <span className="tw-text-sm tw-font-semibold tw-text-center">{item.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;

const getInitials = (name) => name ? name.split(' ').map(n => n[0]).join('').toUpperCase() : '';
