import React, { useState, useCallback, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';
import PartyCard from '../../components/Party/PartyCard';
import startCase from 'lodash/startCase';

const Search = () => {
	const { makeRequest } = useRequest();

	const [parties, setParties] = useState([]);
	const [searchActive, setSearchActive] = useState(false);
	const [searchParams, setSearchParams] = useState({
		name: '',
		price: 1000,
		venue: [],
		type: [],
		cuisine: [],
		activity: []
	});

	const handleInputChange = useCallback((e) => {
		const { name, value, type, checked } = e.target;

		if (type === 'range') {
			const percent = (value / e.target.max) * 100;
			e.target.style.background = `linear-gradient(to right, #fb880c 0%, #fb880c ${percent}%, white ${percent}%, white 100%)`;
		}

		setSearchParams(prev => ({
			...prev,
			[name]: type === 'checkbox'
				? (checked ? [...prev[name], value] : prev[name].filter(item => item !== value))
				: value
		}));
	}, []);

	const handleSubmit = useCallback((e) => {
		e?.preventDefault();
		makeRequest({
			url: '/api/party/v1/search',
			method: 'POST',
			body: searchParams,
			onSuccess: (data) => {
				setParties(data.parties);
				setSearchActive(true);
				window.scrollTo(0, 0);
			},
			onFailure: (error) => {
				showToast(error?.message || 'Search failed', toastType.ERROR);
			},
		});
	}, [searchParams]);

	const handleReset = useCallback(() => {
		setSearchParams({
			name: '',
			price: 1000,
			venue: [],
			type: [],
			cuisine: [],
			activity: []
		});
		setSearchActive(false);
	}, []);

	const handleRemoveChip = useCallback((category, value) => {
		setSearchParams(prev => {
			const updatedCategory = category.toLowerCase();
			const updatedParams = { ...prev };

			if (Array.isArray(prev[updatedCategory])) {
				updatedParams[updatedCategory] = prev[updatedCategory].filter(item => item !== value);
			} else if (typeof prev[updatedCategory] === 'string') {
				updatedParams[updatedCategory] = '';
			} else if (updatedCategory === 'price') {
				updatedParams[updatedCategory] = 1000;
			}

			return updatedParams;
		});
	}, []);

	useEffect(() => {
		if (searchActive) {
			handleSubmit();
		}
	}, [searchParams]);

	useEffect(() => {
		const rangeInput = document.querySelector('input[type="range"]');
		if (rangeInput) {
			const percent = (searchParams.price / rangeInput.max) * 100;
			rangeInput.style.background = `linear-gradient(to right, #fb880c 0%, #fb880c ${percent}%, white ${percent}%, white 100%)`;
		}
	}, []);

	const renderSearchChips = () => {
		const chips = [];
		Object.entries(searchParams).forEach(([category, value]) => {
			if (Array.isArray(value)) {
				value.forEach(item => {
					chips.push({ category: startCase(category), value: startCase(item) });
				});
			} else if (value && (typeof value === 'string' ? value.trim() !== '' : value > 0)) {
				chips.push({ category: startCase(category), value: startCase(value.toString()) });
			}
		});

		return chips.map(({ category, value }) => (
			<div key={`${category}-${value}`} className="tw-bg-gray-700 tw-text-white tw-rounded-full tw-px-3 tw-py-1 tw-flex tw-items-center">
				<span>{`${category}: ${category === 'Price' ? '₹' : ''}${value}`}</span>
				{category !== 'Price' && (
					<ion-icon 
						style={{ cursor: 'pointer', fontSize: '1.2rem' }} 
						onClick={() => handleRemoveChip(category, value)} 
						name="close-outline"
					></ion-icon>
				)}
			</div>
		));
	};

	if (searchActive) {
		return (
			<div className="tw-text-white tw-container tw-mx-auto tw-px-4 tw-py-8">
				<div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-4">
					{renderSearchChips()}
					{Object.values(searchParams).some(value =>
						(Array.isArray(value) && value.length > 0) ||
						(typeof value === 'string' && value.trim() !== '') ||
						(typeof value === 'number' && value > 0)
					) &&
						<button
							onClick={handleReset}
							className="tw-bg-red-500 tw-text-white tw-rounded-full tw-px-3 tw-py-1"
						>
							Clear All
						</button>
					}
				</div>
				<h2 className="tw-text-2xl tw-font-bold tw-mb-4">Search Results</h2>
				<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
					{parties.map((party) => (
						<PartyCard key={party.id} party={party} openinNewTab={true} />
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="tw-bg-black tw-text-white tw-min-h-screen tw-pb-24">
			<h1 className="tw-text-2xl tw-font-bold tw-my-4 tw-text-center">Search Parties</h1>
			<form onSubmit={handleSubmit} className="tw-container tw-mx-auto tw-pb-8">
				<input
					type="text"
					name="name"
					value={searchParams.name}
					onChange={handleInputChange}
					className="tw-w-full tw-bg-[#393737] tw-text-white tw-rounded-lg tw-py-3 tw-px-6 tw-mb-4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
					placeholder="Search parties by name"
				/>

				<div className="tw-bg-[#393737] tw-rounded-lg tw-p-4 tw-mb-4">
					<h2 className="tw-text-xl tw-font-semibold tw-mb-2 tw-text-left">Price Range:</h2>
					<p className="tw-text-lg tw-mb-2">Free - ₹ {searchParams.price}</p>
					<input
						type="range"
						name="price"
						min="0"
						max="100000"
						value={searchParams.price}
						onChange={handleInputChange}
						className="tw-w-full"
					/>
				</div>

				{['venue', 'type', 'cuisine', 'activity'].map((category) => (
					<div key={category} className="tw-bg-[#393737] tw-rounded-lg tw-p-4 tw-mb-4">
						<h2 className="tw-text-xl tw-font-semibold tw-text-left tw-mb-2">
							{category === 'type' ? 'Party Type' : category.charAt(0).toUpperCase() + category.slice(1)}:
						</h2>
						<div className="tw-grid tw-grid-cols-2 tw-gap-2">
							{getOptionsForCategory(category).map((option) => (
								<label key={option} className="tw-flex tw-items-center tw-text-left">
									<input
										type="checkbox"
										name={category}
										value={option}
										checked={searchParams[category].includes(option)}
										onChange={handleInputChange}
										className="tw-mr-2"
									/>
									{option}
								</label>
							))}
						</div>
					</div>
				))}

				<div className="tw-flex tw-justify-end tw-mt-2 tw-gap-2">
					<button
						type="button"
						onClick={handleReset}
						className="tw-bg-white tw-text-black tw-rounded-full tw-py-2 tw-px-6 tw-font-semibold"
					>
						Reset
					</button>
					<button
						type="submit"
						className="tw-bg-orange-500 tw-text-white tw-rounded-full tw-py-2 tw-px-6 tw-font-semibold"
					>
						Search
					</button>
				</div>
			</form>

			<style>
				{`
					input[type="checkbox"] {
						-webkit-appearance: none;
						appearance: none;
						width: 20px;
						height: 20px;
						border: 2px solid #fb880c;
						border-radius: 4px;
						outline: none;
						cursor: pointer;
						position: relative;
					}

					input[type="checkbox"]:checked {
						background-color: #fb880c;
					}

					input[type="checkbox"]:checked::after {
						content: '';
						position: absolute;
						left: 6px;
						top: 2px;
						width: 6px;
						height: 12px;
						border: solid black;
						border-width: 0 3px 3px 0;
						transform: rotate(45deg);
						}

					input[type="range"] {
						-webkit-appearance: none;
						height: 8px;
						background: white;
						border-radius: 4px;
					}

					input[type="range"]::-webkit-slider-thumb {
						-webkit-appearance: none;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						background: #fb880c;
						cursor: pointer;
						box-shadow: 0 0 2px 0 #555;
					}

					input[type="range"]::-webkit-slider-runnable-track {
						-webkit-appearance: none;
						box-shadow: none;
						border: none;
						background: transparent;
					}
                `}
			</style>
		</div>
	);
};

const getOptionsForCategory = (category) => {
	const options = {
		venue: ['Club', 'Bar', 'Lounge', 'Private', 'Residence', 'Rooftop'],
		type: ['House Party', 'Cocktail Party', 'Pool Party', 'Costume Party', 'Open Bar Party', 'Dance Party', 'Themed Party', 'Garden Party', 'BBQ Party', 'Normal Party', 'Networking Party', 'Dinner Party', 'DJ Party'],
		cuisine: [
			'Italian', 'Chinese', 'Indian', 'Mexican', 'French', 'Japanese', 'Mediterranean', 'Thai',
			'Middle Eastern', 'Korean', 'Vietnamese', 'Caribbean', 'African', 'American', 'Fusion', 'OtherCuisine',
		],
		activity: ['Sports Screening', 'Photo Booth', 'Live Performances', 'Guest Speakers', 'Stand-up Comedy', 'Meetups']
	};
	return options[category] || [];
};

export default Search;