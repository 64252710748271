import React, { useEffect, useState } from 'react'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import moment from 'moment'

const Bookings = () => {

    const [bookings, setBookings] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const { makeRequest } = useRequest()

    useEffect(() => {
        getBookings()
    }, [])

    const getBookings = () => {
        makeRequest({
            url: '/api/booking/v1',
            method: 'GET',
            onSuccess: ({ bookings }) => {
                setIsLoading(false)
                setBookings(bookings)
            },
            onFailure: (error) => {
                setIsLoading(false)
                showToast(error?.message || 'Failed to fetch bookings', toastType.ERROR)
            }
        })
    }

    if (isLoading) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
            <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
        </div>
    }

    return (
        <div className='tw-min-h-screen tw-text-white'>
            <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                <button onClick={() => window.history.back()} className="tw-text-2xl tw-flex tw-items-center">
                    <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                </button>
                <h1 className='tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2'>Bookings</h1>
            </div>

            {bookings?.length === 0 && (
                <h1 className='tw-flex tw-justify-center tw-text-center tw-text-gray-400 tw-text-lg'>
                    No bookings found
                </h1>
            )}

            {bookings?.map((booking) => (
                <a
                    href={`/booking/${booking.id}`}
                    key={booking.id}
                    className="tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4 tw-mb-6 tw-flex tw-justify-between tw-items-center 
                    tw-cursor-pointer hover:tw-border-orange-500 hover:tw-border-2"
                >
                    <div className="tw-space-y-2 tw-text-left">
                        <p className="tw-text-gray-300">
                            <span className="tw-inline-block tw-w-24">Booking ID :</span>
                            {booking.id}
                        </p>
                        <p className="tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
                            <ion-icon name="calendar"></ion-icon>
                            {moment(booking.createdAt).format('DD MMMM YYYY')}
                        </p>
                        <p className="tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
                            <ion-icon name="people"></ion-icon>
                            Guests : {booking.guestInfo.length}
                        </p>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-4">
                        <p className="tw-text-xl">
                            <span className="tw-font-normal">₹</span>
                            {booking.paymentInfo.totalPrice.toFixed(2)}
                        </p>
                    </div>
                </a>
            ))}
        </div>
    )
}

export default Bookings