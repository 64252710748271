import { Link } from 'react-router-dom'
import useRequest from '../../hooks/useRequest'
import React, { useEffect, useState } from 'react'
import PartyCard from '../../components/Party/PartyCard'
import { showToast, toastType } from '../../hooks/useToast'
import StorySection from '../../components/Story/StorySection'

const HomePage = () => {
  const { makeRequest } = useRequest()

  const [parties, setParties] = useState([])
  const [loading, setLoading] = useState(true)
  const [loginMode, setLoginMode] = useState(null)
  const [stories, setStories] = useState([])

  const onDelete = (partyId) => {
    if (window.confirm('Are you sure you want to delete this party?')) {
      makeRequest({
        url: `/api/party/v1/${partyId}`,
        method: 'DELETE',
        onSuccess: () => {
          showToast('Party deleted successfully', toastType.SUCCESS)
          window.location.reload()
        },
        onFailure: (error) => {
          showToast(error?.message || 'Failed to delete party', toastType.ERROR)
        },
      })
    }
  }

  const getParties = () => {
    makeRequest({
      url: '/api/party/v1',
      method: 'GET',
      onSuccess: ({ parties, userRole }) => {
        setParties(parties)
        setLoginMode(userRole ?? localStorage.getItem('role'))
        setLoading(false)
      }
    })
  }

  const getStories = () => {
    makeRequest({
      url: '/api/user/v1/stories',
      method: 'GET',
      onSuccess: ({ stories }) => {
        setStories(stories)
      }
    })
  }

  useEffect(() => {
    getParties()
  }, [])

  useEffect(() => {
    if (loginMode === 'guest') getStories()
  }, [loginMode])

  if (loading || !loginMode) {
    return <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
      <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
    </div>
  }

  return (
    <div className="tw-text-white tw-bg-black tw-min-h-screen tw-flex tw-flex-col">
      <div className="tw-flex-1 tw-py-3 tw-flex tw-flex-col">

        {stories?.length > 0 && loginMode === 'guest' && (
          <div className=' tw-mb-4'>
            <StorySection stories={stories} />
          </div>
        )}

        {loginMode === 'guest' ?
          <GuestParties parties={parties} onDelete={onDelete} /> :
          <HostParties parties={parties} onDelete={onDelete} />
        }

        {parties?.length === 0 &&
          <div className="tw-col-span-full tw-flex tw-justify-center tw-items-center tw-h-[70vh]">
            <h1 className="tw-text-white tw-text-4xl md:tw-text-4xl tw-font-bold">
              No Parties
            </h1>
          </div>
        }
      </div>
    </div>
  )
}

const GuestParties = ({ parties, onDelete }) => {
  return (
    <>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        {parties?.length > 0 && parties?.map((party) =>
          <PartyCard key={party.id} party={party} onDelete={onDelete} />
        )}
      </div>
    </>
  )
}

const HostParties = ({ parties, onDelete }) => {
  return (
    <>
      <div className='tw-flex tw-justify-between tw-items-center tw-mb-4'>
        <h1 className='tw-text-white tw-font-bold tw-text-2xl'>Your Parties</h1>
        <Link to='/party/create' className='tw-bg-orange-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-full tw-text-sm tw-font-semibold hover:tw-bg-orange-600'>
          Create Party
        </Link>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        {parties?.length > 0 && parties?.map((party) =>
          <PartyCard key={party.id} party={party} onDelete={onDelete} />
        )}
      </div>
    </>
  )
}

export default HomePage
