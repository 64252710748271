import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';

const Wallet = () => {
    const [balance, setBalance] = useState(0)
    const [loading, setLoading] = useState(true)
    const { makeRequest } = useRequest()
    const navigate = useNavigate();

    useEffect(() => {
        fetchWalletBalance()
    }, [])

    const fetchWalletBalance = () => {
        makeRequest({
            url: '/api/user/v1/wallet',
            method: 'GET',
            onSuccess: (data) => {
                setBalance(data.balance)
                setLoading(false)
            },
            onFailure: (error) => {
                showToast(error?.message || 'Failed to fetch wallet balance', toastType.ERROR)
                setLoading(false)
            },
        })
    }

    const handleWithdrawalRequest = () => {
        navigate('/wallet/requests');
    };

    if (loading) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
            <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
        </div>
    }

    return (
        <div className="tw-min-h-screen tw-bg-[#161616] tw-text-white tw-p-4 md:tw-p-8">
            <div className="tw-max-w-4xl tw-mx-auto">
                <div className='tw-flex tw-justify-between tw-items-center tw-mb-4'>
                    <h3 className="tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-4tw-mb-8">
                        Wallet
                    </h3>
                    <button
                        onClick={handleWithdrawalRequest}
                        className="tw-bg-orange-500 hover:tw-bg-orange-600 tw-text-white tw-p-2 tw-rounded-full"
                    >
                        Withdrawal Request
                    </button>
                </div>

                <div className="tw-bg-[#2c2c2c] tw-rounded-lg tw-p-6 tw-text-center tw-mb-8">
                    <h4 className="tw-text-lg tw-mb-2">Your Current Balance</h4>
                    <p className="tw-text-3xl tw-font-bold tw-text-[#fb880c]">₹{balance.toFixed(2)}</p>
                </div>
                <WithdrawalComponent balance={balance} />
            </div>
        </div>
    )
}

const WithdrawalComponent = ({ balance }) => {
    const [formData, setFormData] = useState({
        amount: '',
        name: '',
        accountNumber: '',
        ifsc: '',
        holder: ''
    });
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const { makeRequest } = useRequest();

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = () => {
        makeRequest({
            url: '/api/user/v1',
            method: 'GET',
            onSuccess: ({ user }) => {
                setFormData(prevState => ({
                    ...prevState,
                    name: user?.bankAccount?.name || '',
                    accountNumber: user?.bankAccount?.accountNumber || '',
                    ifsc: user?.bankAccount?.ifsc || '',
                    holder: user?.bankAccount?.holder || ''
                }));
                setLoading(false);
            },
            onFailure: (error) => {
                showToast(error?.message || 'Failed to fetch user details', toastType.ERROR);
                setLoading(false);
            },
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (parseFloat(formData.amount) > balance) {
            showToast('Insufficient balance', toastType.ERROR);
            return;
        }
        if (parseFloat(formData.amount) <= 0) {
            showToast('Amount must be greater than 0', toastType.ERROR);
            return;
        }

        setSubmitting(true)

        makeRequest({
            url: '/api/user/v1/wallet/withdraw',
            method: 'POST',
            body: formData,
            onSuccess: (data) => {
                showToast('Withdrawal request submitted successfully', toastType.SUCCESS);
            },
            onFailure: (error) => {
                showToast(error?.message || 'Failed to submit withdrawal request', toastType.ERROR);
            },
            finally: () => {
                setSubmitting(false)
            }
        });
    };

    if (loading) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-h-24">
            <div className="tw-w-8 tw-h-8 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
        </div>;
    }

    return (
        <div className="tw-rounded-lg">
            <h4 className="tw-text-xl tw-font-semibold tw-text-left tw-mb-6">Withdrawal Request</h4>
            <form onSubmit={handleSubmit} className="tw-space-y-6">
                <div>
                    <label className="tw-block tw-text-lg tw-mb-2 tw-text-left">Withdrawal Amount*</label>
                    <input
                        type="number"
                        required
                        className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                        name="amount"
                        min={1}
                        value={formData.amount}
                        disabled={balance <= 1}
                        max={balance}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label className="tw-block tw-text-lg tw-mb-2 tw-text-left">Bank Name*</label>
                    <input
                        type="text"
                        required
                        className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                        name="name"
                        value={formData?.name}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label className="tw-block tw-text-lg tw-mb-2 tw-text-left">Account Number*</label>
                    <input
                        type="number"
                        required
                        className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label className="tw-block tw-text-lg tw-mb-2 tw-text-left">IFSC Code*</label>
                    <input
                        type="text"
                        required
                        className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                        name="ifsc"
                        value={formData.ifsc}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label className="tw-block tw-text-lg tw-mb-2 tw-text-left">Account Holder Name*</label>
                    <input
                        type="text"
                        required
                        className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                        name="holder"
                        value={formData.holder}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="tw-flex tw-justify-center">

                    <button
                        type="submit"
                        className={`tw-w-full tw-mb-16 tw-bg-orange-500 hover:tw-bg-orange-600 tw-text-white tw-text-xl tw-py-3 tw-rounded-full tw-mt-6 ${balance <= 0 ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`}
                        disabled={balance <= 0 || submitting}
                    >
                        Submit Withdrawal Request
                    </button>
                </div>

            </form>
        </div>
    );
};

export default Wallet
