import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';
import UserList from '../../components/UserList';

const Followers = () => {
    const { id } = useParams();
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { makeRequest } = useRequest();

    useEffect(() => {
        fetchFollowers();
    }, []);

    const fetchFollowers = () => {
        makeRequest({
            url: `/api/user/v1/followers`,
            method: 'GET',
            onSuccess: ({ followers }) => {
                setFollowers(followers);
                setLoading(false);
            },
            onFailure: (error) => {
                showToast(error.message, toastType.ERROR);
                setLoading(false);
            }
        });
    };

    const handleRemove = (followerId) => {
        makeRequest({
            url: `/api/user/v1/remove-follower/${followerId}`,
            method: 'POST',
            onSuccess: () => {
                showToast('Removed follower successfully', toastType.SUCCESS)
                fetchFollowers()
            },
            onError: (error) => {
                showToast(error.message, toastType.ERROR)
            }
        })
    }

    if (loading) {
        return (
            <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
            </div>
        );
    }

    return (
        <UserList
            title="Followers"
            users={followers}
            emptyMessage="No followers found."
            actionButton="Remove"
            onActionClick={handleRemove}
        />
    );
};

export default Followers;