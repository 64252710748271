import React, { useEffect, useState } from 'react'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import moment from 'moment'

const HostBookings = () => {
    const { makeRequest } = useRequest()
    const [bookings, setBookings] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchBookings()
    }, [])

    const fetchBookings = async () => {
        makeRequest({
            url: '/api/booking/v1/',
            method: 'GET',
            onSuccess: ({ bookings }) => {
                setIsLoading(false)
                setBookings(bookings)
            },
            onError: (error) => {
                showToast(error.message || 'Failed to fetch bookings', toastType.ERROR)
            }
        })
    }

    if (isLoading) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
            <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
        </div>
    }

    return (
        <div className='tw-min-h-screen tw-text-white tw-p-4'>
           <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                <button onClick={() => window.history.back()} className="tw-text-2xl tw-flex tw-items-center">
                    <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                </button>
                <h1 className='tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2'>Your Parties</h1>
            </div>

            {bookings?.length === 0 && (
                <h1 className='tw-flex tw-justify-center tw-text-center tw-text-gray-400 tw-text-lg'>
                    No party found
                </h1>
            )}

            {bookings?.map((booking) => (
                <a
                    href={`/host/booking/party/${booking.id}`}
                    key={booking.id}
                    className="tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4 tw-mb-6 tw-flex tw-justify-between tw-items-center 
                    tw-cursor-pointer hover:tw-border-orange-500 hover:tw-border-2"
                >
                    <div className="tw-space-y-2 tw-text-left">
                        <p className="tw-text-gray-300">{booking.name}</p>
                        <p className="tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
                            <ion-icon name="location"></ion-icon>
                            {booking.location.venue}, {booking.location.address}
                        </p>
                        <p className="tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
                            <ion-icon name="calendar"></ion-icon>
                            {moment(booking.date).format('DD MMMM YYYY')}, {moment(booking.time, 'HH:mm').format('hh:mm A')}
                        </p>
                        <p className="tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
                            <ion-icon name="cash"></ion-icon>
                            ₹ {booking.fees}
                        </p>
                    </div>
                    {/* <div className="tw-flex tw-items-center tw-gap-4">
                        <span className="tw-text-xl">₹ {booking?.paymentInfo.totalPrice.toFixed(2)}</span>
                    </div> */}
                </a>
            ))}
        </div>
    )
}

export default HostBookings