import React, { useEffect, useState } from 'react'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import UserList from '../../components/UserList'

const Following = () => {

  const { makeRequest } = useRequest()
  const [followings, setFollowings] = useState([])
  const [loading, setLoading] = useState(true)
  const userId = JSON.parse(localStorage.getItem('user')).userId

  useEffect(() => {
    getFollowings()
  }, [])

  const getFollowings = () => {
    makeRequest({
      url: `/api/user/v1/followings`,
      method: 'GET',
      onSuccess: ({ followings }) => {
        setFollowings(followings)
        setLoading(false)
      },
      onError: (error) => {
        showToast(error.message, toastType.ERROR)
        setLoading(false)
      }
    })
  }

  if (loading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
      </div>
    );
  }

  const handleUnfollow = (followingId) => {
    makeRequest({
      url: `/api/user/v1/unfollow/${followingId}`,
      method: 'POST',
      onSuccess: () => {
        showToast('Unfollowed successfully', toastType.SUCCESS)
        getFollowings()
      },
      onError: (error) => {
        showToast(error.message, toastType.ERROR)
      }
    })
  }

  return (
    <UserList
      title="Followings"
      users={followings}
      emptyMessage="No following found."
      actionButton="Unfollow"
      onActionClick={handleUnfollow}
    />
  )
}

export default Following