import useRequest from '../../hooks/useRequest';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ImageViewer from '../../components/ImageViewer';
import { showToast, toastType } from '../../hooks/useToast';
import { formatDate, formatTime } from '../../helpers/time';
import { formatCurrency } from '../../helpers/formate-currency';
import { enivornment } from '../../helpers/environment';
import moment from 'moment';

const PartyDetails = () => {
    const [partyDetails, setPartyDetails] = useState(null);
    const [viewingImage, setViewingImage] = useState(null);
    const isHost = localStorage.getItem('role') === 'host';
    const navigate = useNavigate();

    const { id } = useParams();
    const { makeRequest } = useRequest();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPartyDetails();
    }, [id]);

    const fetchPartyDetails = () => {
        makeRequest({
            url: `/api/party/v1/${id}`,
            method: 'GET',
            onSuccess: ({ party, following }) => setPartyDetails({ ...party, following }),
            onFailure: (error) => showToast(error?.message || 'Failed to fetch party details', toastType.ERROR),
        });
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this party?')) {
            makeRequest({
                url: `/api/party/v1/${id}`,
                method: 'DELETE',
                onSuccess: () => {
                    showToast('Party deleted successfully', toastType.SUCCESS);
                    navigate('/home');
                },
                onFailure: (error) => showToast(error?.message || 'Failed to delete party', toastType.ERROR),
            });
        }
    };

    const handleFollow = () => {
        makeRequest({
            url: `/api/user/v1/follow/${partyDetails.organizerId}`,
            method: 'POST',
            onSuccess: ({ message }) => {
                showToast(message, toastType.SUCCESS);
                fetchPartyDetails()
            },
            onFailure: (error) => showToast(error?.message || 'Failed to follow organizer', toastType.ERROR),
        });
    };

    if (!partyDetails) {
        return (
            <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
            </div>
        )
    }

    const ConditionalButton = ({ show, children }) => show ? children : null;

    const renderImages = (images, label, onClick) => (
        images && images.length > 0 && (
            <div className="tw-bg-[#393737] tw-p-4 tw-rounded-lg tw-mb-6">
                <h3 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-left">{label}</h3>
                <div className="tw-flex tw-flex-wrap tw-gap-2">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={`${enivornment === 'LIVE' ? 'https://partyconnecthub.com' : 'http://localhost:5000'}${image}`}
                            alt={`${label} ${index + 1}`}
                            className="tw-w-24 tw-h-24 tw-object-cover tw-rounded"
                            onClick={() => onClick(image)}
                        />
                    ))}
                </div>
            </div>
        )
    )

    const renderDetailsSection = (label, items) => (
        items && items.length > 0 && (
            <div className="tw-bg-[#393737] tw-p-4 tw-rounded-lg tw-mb-6">
                <h3 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-left">{label}</h3>
                <ul className="tw-list-disc tw-list-inside tw-pl-4 tw-text-left">
                    {items.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
            </div>
        )
    )

    const isPartyEnded = moment(partyDetails.date).isBefore(moment()) ||
        (moment(partyDetails.date).isSame(moment(), 'day') && partyDetails.time < moment().format('HH:mm'));

    const canEditOrDelete = isHost && !isPartyEnded

    const canBook = !isHost && !isPartyEnded

    return (
        <div className="tw-bg-black tw-text-white">
            <div className="tw-max-w-3xl tw-mx-auto">
                <div className='tw-flex tw-items-center tw-mb-4'>
                    <button onClick={() => window.history.back()} className="tw-text-white tw-text-2xl tw-flex tw-items-center tw-mr-4">
                        <ion-icon name="arrow-back-outline" className="tw-mr-2" />
                    </button>
                    <h1 className="tw-text-2xl tw-font-bold tw-text-left">Party Details</h1>
                </div>

                <div className="tw-bg-dark tw-rounded-lg tw-overflow-hidden tw-shadow-lg">
                    <div className="tw-relative">
                        {partyDetails?.coverImage && (
                            <img
                                src={`${enivornment === 'LIVE' ? 'https://partyconnecthub.com' : 'http://localhost:5000'}${partyDetails.coverImage}`}
                                alt="Party"
                                className="tw-w-full tw-h-64 tw-object-cover"
                            />
                        )}
                        <ConditionalButton show={canEditOrDelete}>
                            <div className="tw-absolute tw-top-2 tw-right-2 tw-flex tw-space-x-2">
                                <Link to={`/party/${id}/edit`} className="tw-bg-blue-500 tw-text-white tw-px-3 tw-py-1 tw-rounded tw-text-xs tw-font-semibold hover:tw-bg-blue-600 tw-transition-colors tw-duration-300">
                                    Edit
                                </Link>
                                <button
                                    onClick={handleDelete}
                                    className="tw-bg-red-500 tw-text-white tw-px-3 tw-py-1 tw-rounded tw-text-xs tw-font-semibold hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
                                >
                                    Delete
                                </button>
                            </div>
                        </ConditionalButton>
                    </div>

                    <div className="tw-p-2">
                        <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                            <h2 className="tw-text-2xl tw-w-[67%] tw-font-bold tw-text-left">{partyDetails.name || 'Party'}</h2>
                            <ConditionalButton show={canBook}>
                                <Link to={`/book/${partyDetails.id}`} className="tw-bg-orange-500 tw-text-white tw-py-2 tw-px-4 tw-rounded-full tw-font-semibold hover:tw-bg-orange-600">
                                    Book Now
                                </Link>
                            </ConditionalButton>
                            {!canBook && !isHost && <div className='tw-text-orange-500 tw-text-sm tw-font-semibold'>Booking Closed</div>}
                        </div>

                        <div className='tw-flex tw-flex-row tw-gap-2 tw-items-center tw-mb-4'>
                            <div className='tw-text-left tw-text-sm'>Hosted by {partyDetails.organizer?.name || 'Host'}</div>
                            {!isHost && (
                                <button onClick={handleFollow} className='tw-text-orange-500 tw-border-none tw-rounded-full tw-text-sm tw-font-semibold tw-bg-transparent tw-py-1 tw-px-2'>
                                    {partyDetails?.following ? 'Unfollow' : 'Follow'}
                                </button>
                            )}
                        </div>

                        <div className="tw-flex tw-justify-between tw-bg-[#393737] tw-p-4 tw-rounded-lg tw-mb-6">
                            <div className="tw-flex tw-items-center">
                                <ion-icon name="calendar-outline" class="tw-mr-2 tw-text-xl"/>
                                {formatDate(partyDetails.date)}
                            </div>
                            <div className="tw-flex tw-items-center">
                                <ion-icon name="time-outline" class="tw-mr-2 tw-text-xl"/>
                                {formatTime(partyDetails.time)}
                            </div>
                            <div className="tw-flex tw-items-center">
                                <ion-icon name="cash-outline" class="tw-mr-2 tw-text-xl"/>
                                {formatCurrency(partyDetails.fees)}
                            </div>
                        </div>

                        {partyDetails.description && <p className="tw-mb-6 tw-text-left">{partyDetails.description}</p>}

                        {(partyDetails?.location?.venue || partyDetails?.location?.address || partyDetails?.location?.city) && (
                            <div className="tw-bg-[#393737] tw-p-4 tw-rounded-lg tw-mb-6">
                                <h3 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-left">Venue</h3>
                                <p className="tw-text-orange-500 tw-text-left">{partyDetails.location.venue}</p>
                                <p className='tw-text-left'>{[partyDetails.location.address, partyDetails.location.city].filter(Boolean).join(', ')}</p>
                                <p className='tw-text-left'>Parking: {partyDetails.location.parkingAvailability ? 'Available' : 'Not Available'}</p>
                            </div>
                        )}

                        {renderDetailsSection('Party Type', partyDetails.types)}
                        {renderDetailsSection('Cuisine', partyDetails.cuisines)}
                        {renderDetailsSection('Activities', partyDetails.activities)}
                        {renderImages(partyDetails.menu, 'Menu', setViewingImage)}
                        {renderImages(partyDetails.drinks, 'Drinks', setViewingImage)}
                        {renderImages(partyDetails.gallery, 'Gallery', setViewingImage)}
                    </div>
                </div>
            </div>

            {viewingImage && <ImageViewer imageUrl={viewingImage} onClose={() => setViewingImage(null)} />}
        </div>
    );
};

export default PartyDetails;