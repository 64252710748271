import moment from 'moment'
import useRequest from '../../hooks/useRequest'
import React, { useState, useCallback } from 'react'
import { emailRegex } from '../../constants/regex'
import { showToast, toastType } from '../../hooks/useToast'
import { Button, DatePicker, Image, Input } from '@nextui-org/react'

const Register = () => {
    const [formState, setFormState] = useState({
        isLoading: false,
        showPassword: false,
        errors: { name: false, email: false, phone: false, dob: false, password: false, confirmPassword: false }
    })

    const { makeRequest } = useRequest()

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        return passwordRegex.test(password)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormState(prev => ({ ...prev, isLoading: true }))

        const formData = Object.fromEntries(new FormData(e.target))
        const { name, email, phone, dob, password, confirmPassword } = formData

        const validations = {
            name: name.length >= 3,
            email: emailRegex.test(email),
            phone: phone.length === 10,
            dob: moment().diff(moment(dob, "YYYY-MM-DD"), 'years') >= 18,
            password: validatePassword(password),
            confirmPassword: password === confirmPassword
        }

        const hasErrors = Object.entries(validations).some(([key, isValid]) => {
            if (!isValid) {
                setFormState(prev => ({
                    ...prev,
                    errors: { ...prev.errors, [key]: true }
                }))
                return true
            }
            return false
        })

        if (hasErrors) {
            setFormState(prev => ({ ...prev, isLoading: false }))
            return
        }
        registerUser({ name, email, password, phone, dob })
    }

    const registerUser = (userData) => {
        makeRequest({
            url: '/api/auth/v1/register',
            method: 'POST',
            body: userData,
            onSuccess: ({ message, token, user }) => {
                if (token) {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify({
                        userId: user.id,
                        role: user.role,
                        profile: user.profile
                    }))
                    localStorage.setItem('role', user.role)
                    showToast('Registration Successful', toastType.SUCCESS)
                    setTimeout(() => {
                        window.location.assign(`/home`)
                    }, 1500)
                } else {
                    window.location.assign(`/login`)
                }
            },
            onFailure: (error) => {
                setFormState(prev => ({ ...prev, isLoading: false }))
                showToast(error.message, toastType.ERROR)
            },
        })
    }

    const togglePasswordVisibility = useCallback(() => {
        setFormState(prev => ({ ...prev, showPassword: !prev.showPassword }))
    }, [])

    const handleInputChange = (field) => {
        setFormState(prev => ({
            ...prev,
            errors: { ...prev.errors, [field]: false }
        }))
    }

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="tw-flex tw-flex-col tw-h-screen tw-overflow-y-auto tw-bg-gradient-to-r tw-from-black tw-to-gray-900">

            <div className="tw-mt-2 tw-flex tw-items-center tw-w-full tw-justify-center">
                <button name="back" onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-absolute tw-left-4">
                    <ion-icon name={"arrow-back-outline"}  />
                </button>
                <Image
                    width="100"
                    loading="lazy"
                    alt=""
                    className="tw-items-center"
                    src="/assets/images/party-connect-hub.svg"
                />
            </div>

            <div className="tw-flex tw-flex-1">
                <div className="tw-hidden md:tw-flex tw-w-1/2 tw-flex-col tw-items-center tw-justify-center tw-px-10">
                    <Image
                        width="200"
                        loading="lazy"
                        alt=""
                        className="tw-items-center tw-my-2"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                    />

                    <h1 className="tw-text-4xl tw-font-bold tw-mb-2">PartyConnectHub</h1>
                    <p className="tw-text-gray-300">Discover and join exciting Parties</p>
                </div>

                <div className="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-py-3">
                    <h2 className="tw-text-3xl tw-font-bold">Welcome</h2>
                    <p className="tw-text-gray-300 tw-mb-4">Create an account to get started</p>

                    <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-1">
                        <Input
                            variant='bordered'
                            name="name"
                            type="text"
                            label="Name"
                            placeholder="Enter your name"
                            isInvalid={formState.errors.name}
                            errorMessage="Name must be at least 3 characters"
                            classNames={{ errorMessage: "tw-text-left" }}
                            endContent={
                                <ion-icon name="person" style={{ fontSize: '1.3rem', color: '#808080' }} />
                            }
                            onValueChange={() => handleInputChange('name')}
                        />

                        <Input
                            name="email"
                            type="email"
                            label="Email"
                            variant='bordered'
                            placeholder="Enter your email"
                            isInvalid={formState.errors.email}
                            errorMessage="Invalid email"
                            classNames={{ errorMessage: "tw-text-left" }}
                            endContent={
                                <ion-icon name="mail" style={{ fontSize: '1.3rem', color: '#808080' }} />
                            }
                            onValueChange={() => handleInputChange('email')}
                        />

                        <Input
                            type="tel"
                            name="phone"
                            label="Phone"
                            maxLength={10}
                            variant='bordered'
                            placeholder="Enter your phone number"
                            isInvalid={formState.errors.phone}
                            errorMessage="Invalid phone number"
                            classNames={{ errorMessage: "tw-text-left" }}
                            endContent={
                                <ion-icon name="phone-portrait" style={{ fontSize: '1.3rem', color: '#808080' }} />
                            }
                            onValueChange={() => handleInputChange('phone')}
                        />

                        <DatePicker
                            variant='bordered'
                            label="Date of Birth"
                            name="dob"
                            showMonthAndYearPickers
                            isInvalid={formState.errors.dob}
                            errorMessage="User must be at least 18 years old"
                            formatOptions={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            classNames={{ errorMessage: "tw-text-left", base: "tw-text-left" }}
                            onChange={() => handleInputChange('dob')}
                        />

                        <Input
                            variant='bordered'
                            name="password"
                            label="Password"
                            placeholder="Enter your password"
                            isInvalid={formState.errors.password}
                            classNames={{ errorMessage: "tw-text-left" }}
                            type={formState.showPassword ? 'text' : 'password'}
                            errorMessage="Password must include at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
                            endContent={
                                <button className="focus:outline-none" type="button" onClick={togglePasswordVisibility}>
                                    <ion-icon
                                        name={formState.showPassword ? 'eye-off' : 'eye'}
                                        style={{ fontSize: '1.3rem', color: '#808080' }}
                                    />
                                </button>
                            }
                            onValueChange={() => handleInputChange('password')}
                        />

                        <Input
                            variant="bordered"
                            name="confirmPassword"
                            label="Confirm Password"
                            placeholder="Enter your password"
                            isInvalid={formState.errors.confirmPassword}
                            classNames={{ errorMessage: "tw-text-left" }}
                            type={formState.showPassword ? 'text' : 'password'}
                            errorMessage="Passwords must match"
                            onValueChange={() => handleInputChange('confirmPassword')}
                        />

                        <Button
                            size='lg'
                            type='submit'
                            variant="solid"
                            isDisabled={formState.isLoading}
                            isLoading={formState.isLoading}
                            className='tw-font-semibold tw-bg-orange-500'
                        >
                            Register
                        </Button>
                    </form>

                    <label className="tw-text-gray-300 tw-text-sm tw-text-center tw-mt-4 tw-max-w-xs tw-mx-auto">
                        By registering, you confirm that you've read and
                        agree to our <a href="/terms-and-conditions" className="tw-font-semibold hover:tw-underline">Terms</a>
                        {" "}and <a href="/privacy-policy" className="tw-font-semibold hover:tw-underline">Privacy Policy</a>.
                    </label>

                    <div className="tw-text-center tw-mt-4">
                        Already have an account?{" "}
                        <a href={`/login`} className="tw-text-blue-500 hover:tw-underline">
                            Log In
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register