import { startCase } from 'lodash'
import { auth } from '../../helpers/firebase'
import useRequest from '../../hooks/useRequest'
import { PartyIcon } from '../../assets/icons/party'
import { GuitarIcon } from '../../assets/icons/guitar'
import { emailRegex } from '../../constants/regex'
import { showToast, toastType } from '../../hooks/useToast'
import React, { useState, useCallback, useMemo } from 'react'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Button, Divider, Image, Input, Tab, Tabs } from '@nextui-org/react'

const LoginPage = () => {

    const { makeRequest } = useRequest()
    const provider = useMemo(() => new GoogleAuthProvider(), [])

    const [formState, setFormState] = useState({
        role: 'guest',
        isLoading: false,
        showPassword: false,
        errors: { email: false, password: false }
    })

    const togglePasswordVisibility = useCallback(() => {
        setFormState(prev => ({ ...prev, showPassword: !prev.showPassword }))
    }, [])

    const handleRoleSelection = useCallback((newRole) => {
        console.log(newRole)
        setFormState(prev => ({ ...prev, role: newRole }))
    }, [])

    const validateForm = useCallback((email, password) => {
        const errors = {}
        if (!email || !emailRegex.test(email)) errors.email = true
        if (!password) errors.password = true
        return errors
    }, [])

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        
        const formData = new FormData(e.target)
        const email = formData.get('email')
        const password = formData.get('password')

        const errors = validateForm(email, password)
        if (Object.keys(errors).length) {
            setFormState(prev => ({ ...prev, errors }))
            return
        } else {
            setFormState(prev => ({ ...prev, errors: { email: false, password: false } }))
        }

        setFormState(prev => ({ ...prev, isLoading: true }))
        makeRequest({
            url: '/api/auth/v1/login',
            method: 'POST',
            body: { email, password, role: formState.role },
            onSuccess: ({ token, user }) => {
                handleAuthSuccess(token, user)
            },
            onFailure: (error) => {
                showToast(error?.message ?? 'Error', toastType.ERROR)
                setFormState(prev => ({ ...prev, isLoading: false }))
            },
        })
    }, [formState.role])

    const handleAuthSuccess = useCallback((token, user) => {
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify({ 
            userId: user.id, 
            role: user.role, 
            profile: user.profile 
        }))
        localStorage.setItem('role', user.role)
        window.location.assign(`/home`)
        showToast('Login Successful', toastType.SUCCESS)
    }, [])

    const signInWithGoogle = useCallback(async () => {
        try {
            setFormState(prev => ({ ...prev, isLoading: true }))
            const result = await signInWithPopup(auth, provider)

            if (!result.user?.stsTokenManager?.accessToken) {
                throw new Error('Authentication failed')
            }

            makeRequest({
                url: '/api/auth/v1/google-signin',
                method: 'POST',
                body: { 
                    token: result.user.stsTokenManager.accessToken, 
                    role: formState.role 
                },
                onSuccess: ({ token, user }) => {
                    handleAuthSuccess(token, user)
                },
                onFailure: (error) => {
                    showToast(error?.message ?? 'Error', toastType.ERROR)
                    setFormState(prev => ({ ...prev, isLoading: false }))
                },
            })
        } catch (error) {
            showToast("Error during sign-in", toastType.ERROR)
            setFormState(prev => ({ ...prev, isLoading: false }))
        }
    }, [])

    const roleTabs = [
        {
            key: 'guest',
            title: 'Guest',
            icon: <GuitarIcon />
        },
        {
            key: 'host',
            title: 'Host',
            icon: <PartyIcon />
        }
    ]

    return (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-bg-gradient-to-r tw-from-black tw-to-gray-900">
            <Image
                src="/assets/images/party-connect-hub.svg"
                alt="PartyConnectHub Logo"
                width="100"
                loading="lazy"
            />
            <Image
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                alt="Party Connect Hub"
                className="tw-items-center tw-my-4"
                width="150"
            />

            <h1 className="tw-text-white tw-text-center tw-text-3xl tw-font-bold tw-mb-2">
                PartyConnectHub
            </h1>

            <p className="tw-text-gray-300 tw-text-center tw-mb-4">
                Discover and join exciting parties.
            </p>

            <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-4">

                <div className="tw-flex tw-justify-center tw-gap-4">
                    <Tabs
                        size='lg'
                        fullWidth
                        aria-label="Role"
                        variant="bordered"
                        defaultValue={formState.role}
                        classNames={{ cursor: "tw-bg-[#f97316]" }}
                        onSelectionChange={(key) => handleRoleSelection(key)}
                    >
                        {roleTabs.map((tab) => (
                            <Tab
                                key={tab.key}
                                title={
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                        {tab.icon}
                                        <span>{tab.title}</span>
                                    </div>
                                }
                            />
                        ))}
                    </Tabs>
                </div>

                <div className="tw-flex tw-w-full tw-flex-wrap tw-md:tw-flex-nowrap tw-gap-3">
                    <Input
                        name="email"
                        type="email"
                        variant='flat'
                        label="Email"
                        placeholder="Enter your email"
                        isInvalid={formState.errors.email}
                        errorMessage="Invalid email"
                        classNames={{ errorMessage: "tw-text-left" }}
                        endContent={
                            <ion-icon name="mail" style={{ fontSize: '1.3rem', color: '#808080' }} />
                        }
                    />
                    <Input
                        variant="flat"
                        name="password"
                        label="Password"
                        placeholder="Enter your password"
                        errorMessage="Password is required"
                        isInvalid={formState.errors.password}
                        classNames={{ errorMessage: "tw-text-left" }}
                        type={formState.showPassword ? 'text' : 'password'}
                        endContent={
                            <button className="focus:outline-none" type="button" onClick={togglePasswordVisibility} aria-label="toggle password visibility">
                                <ion-icon
                                    name={formState.showPassword ? 'eye-off' : 'eye'}
                                    style={{ fontSize: '1.3rem', color: '#808080' }}
                                />
                            </button>
                        }
                    />
                </div>

                <div className="tw-text-right tw-mt-[-16px]">
                    <a href={`/forgot-password`} className="tw-text-sm tw-text-gray-400 hover:tw-text-white">Forgot Password?</a>
                </div>

                <Button
                    type='submit'
                    variant="solid"
                    size='lg'
                    className=' tw-font-semibold tw-bg-orange-500'
                    isDisabled={formState.isLoading}
                    isLoading={formState.isLoading}
                >
                    Login as {startCase(formState.role)}
                </Button>

                <div className="tw-flex tw-items-center tw-gap-4">
                    <Divider className="tw-bg-gray-600 tw-flex-1" />
                    <p className="tw-shrink-0 tw-text-sm">OR</p>
                    <Divider className="tw-bg-gray-600 tw-flex-1" />
                </div>
                <Button
                    size='lg'
                    variant="bordered"
                    onClick={signInWithGoogle}
                    disabled={formState.isLoading}
                    isLoading={formState.isLoading}
                >
                    <img src="/assets/images/google.svg" alt="Google" className="tw-h-5 tw-w-5" />
                    Continue with Google
                </Button>

            </form>
            <div className="tw-text-center tw-mt-2 tw-mb-3 tw-text-sm">
                {`Need to create an account? `}
                <a href={`/register`} className="tw-font-semibold hover:tw-underline tw-text-blue-500">Sign Up</a>
            </div>
        </div>
    )
}

export default LoginPage
